import React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { StudentChoicesResponse } from '../api';
import { Box } from '@mui/material';

type StudentChoicesTableProps = {
  students: StudentChoicesResponse[];
  loading: boolean;
};

const RenderSubjects = (props: GridRenderCellParams<any, StudentChoicesResponse['subjects']>) => {
  return (
    <div className="vertical-container" style={{ padding: '5px 0' }}>
      {props.value?.map((subject) => (
        <Box sx={{ m: [0, 0.25] }} key={subject.id}>
          {subject.name}
        </Box>
      )) ?? 'No subjects'}
    </div>
  );
};

const columns: GridColDef[] = [
  { field: 'crsid', headerName: 'CRSid', width: 100 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'collegeInstitution', headerName: 'College Institution', width: 250 },
  {
    field: 'subjects',
    headerName: 'Subjects',
    minWidth: 300,
    renderCell: RenderSubjects
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 170,
    renderCell: (c) => (c.value ? new Date(c.value).toLocaleString() : null)
  }
];

const StudentChoicesTable: React.FC<StudentChoicesTableProps> = ({ students, loading }) => {
  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={students}
        columns={columns}
        getRowId={(row: StudentChoicesResponse) => row.crsid}
        getRowHeight={() => 'auto'}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }]
          }
        }}
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default StudentChoicesTable;
