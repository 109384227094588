import React, { useState } from 'react';

import { TextField, Box, Stack, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LoadingButton from '@mui/lab/LoadingButton';
import { studentSelectionStore } from './store';
import { apiFetch, Student } from '../api';
import { modalStore, ModalTypes } from '../AllocatorUIModal';

export default function StudentSelection({ initialCrsid = '' }: { initialCrsid?: string }) {
  const { student, crsid, setCrsid, setStudent } = studentSelectionStore();

  const [crsidInvalid, setCrsidInvalid] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { setModal } = modalStore();

  const handleLookup = async (crsid: string) => {
    setFetching(true);
    try {
      const response = await apiFetch(`/api/v1alpha1/students/${crsid}`, {
        method: 'GET'
      });

      if (!response.ok) {
        if (response.status === 404) {
          setCrsidInvalid(true);
          return;
        }

        throw new Error('Network response was not OK: ' + response.status);
      }

      const student: Student = await response.json();

      setStudent(student);
    } catch (error) {
      setModal(
        'An error occurred during lookup: ' + (error as Error).toString(),
        ModalTypes.ALERT
      );
    } finally {
      setFetching(false);
    }
  };

  const lookupUser = () => {
    handleLookup(crsid);
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center'
        }}
      >
        <TextField
          required
          error={crsidInvalid}
          helperText={crsidInvalid && 'Could not find user'}
          disabled={fetching}
          label="CRSid"
          value={crsid}
          onChange={(e) => {
            setCrsid(e.target.value);
            setStudent(null);
            setCrsidInvalid(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              lookupUser();
            }
          }}
        />
        <LoadingButton
          loading={fetching}
          disabled={!crsid}
          variant="contained"
          onClick={lookupUser}
        >
          <span>Find Student</span>
        </LoadingButton>
      </Stack>
      {student && (
        <StudentInfo
          name={student.name}
          college={student.collegeInstitution?.name || 'Unrecognised Institution'}
        />
      )}
    </Box>
  );
}

const StudentInfo = ({ name, college }: { name: string; college: string }): JSX.Element => (
  <ListItem>
    <ListItemIcon>
      <PersonIcon />
    </ListItemIcon>
    <ListItemText primary={name} secondary={college} />
  </ListItem>
);
