import React from 'react';
import { User } from '../App';
import ProtectedRoute, { Props as ProtectedRouteProps } from './ProtectedRoute';
import { useRouteLoaderData } from 'react-router-dom';

type Props = Omit<ProtectedRouteProps, 'isAllowed'> & {
  requiredPermissions: string[];
  redirectPath?: string;
};

const PermissionedRoute = ({ requiredPermissions, ...rest }: Props) => {
  const user = useRouteLoaderData('root') as User;
  const userPermissions = new Set(user?.permissions);
  const isAllowed = requiredPermissions.every((perm) => userPermissions.has(perm));

  return <ProtectedRoute isAllowed={isAllowed} {...rest} />;
};

export default PermissionedRoute;
