import React, { useMemo } from 'react';

import {
  Typography,
  Toolbar,
  Button,
  Box,
  IconButton,
  AppBar,
  Container,
  Menu,
  MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BiotechIcon from '@mui/icons-material/Biotech';
import { User } from './App';
import { PERMISSION_BY_PATH } from './permission';
import { useRouteLoaderData } from 'react-router-dom';

export interface Page {
  name: string;
  url: string;
  newTab?: boolean;
  permissions: string[];
}

const pages: Page[] = [
  {
    name: 'Student Choices',
    url: '/studentChoices',
    permissions: PERMISSION_BY_PATH.addStudentChoices
  },
  {
    name: 'Allocation',
    url: '/allocation',
    permissions: PERMISSION_BY_PATH.allocation
  },
  {
    name: 'Bulk Choices',
    url: '/bulkChoices',
    permissions: PERMISSION_BY_PATH.bulkChoices
  },
  {
    name: 'Timetable',
    url: '/timetable',
    permissions: PERMISSION_BY_PATH.timetable
  },
  {
    name: 'Admin',
    url: '/admin/',
    newTab: true,
    permissions: PERMISSION_BY_PATH.admin
  }
];

export default function Nav() {
  // anchorEl of the compact nav menu is set dynamically so the menu appears when clicked
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const user = useRouteLoaderData('root') as User;

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    // Currently event.currentTarget is always the menu open button
    setAnchorElNav(event.currentTarget);
  };
  const closeNavMenu = () => {
    setAnchorElNav(null);
  };

  const menuItemClicked = (page: Page) => {
    // FIXME look at switching to react-router mechanism if easy to integrate w/ mui
    if (page.newTab) {
      window.open(page.url, '_blank')?.focus();
    } else {
      window.location.href = `#${page.url}`;
    }

    closeNavMenu();
  };

  const filteredPages = useMemo(() => {
    const userPermissions = new Set(user?.permissions);
    return pages.filter((page) => page.permissions.every((perm) => userPermissions.has(perm)));
  }, [user]);

  return (
    <AppBar position="static">
      <Container>
        <Toolbar disableGutters>
          <BiotechIcon sx={{ display: 'flex', mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit'
            }}
          >
            Lab Allocator
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="Open menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={closeNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page: Page) => (
                <MenuItem
                  key={page.url}
                  onClick={() => {
                    menuItemClicked(page);
                  }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {filteredPages.map((page: Page) => (
              <Button
                key={page.url}
                onClick={() => {
                  menuItemClicked(page);
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
