import Cookies from 'js-cookie';

export function apiFetch(resource: RequestInfo | URL, options: { [index: string]: any }) {
  const { headers: additionHeaders, ...rest } = options;
  return fetch(resource, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken') || '',
      ...additionHeaders
    },
    ...rest
  }).then(async (response) => {
    if (response.status === 403) {
      const json = await response.json();
      if (json.detail.includes('Authentication credentials were not provided')) {
        window.location.href = '/accounts/login/google-oauth2/';
        // Give the browser time to redirect
        await new Promise((resolve, reject) => window.setTimeout(resolve, 1000));
      }
    }
    return response;
  });
}

const fetchCurry = (
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  defaultMethodHeaders?: HeadersInit
) => {
  return async <T,>(resource: RequestInfo | URL, options?: RequestInit): Promise<T> => {
    const headers = Object.assign({}, defaultMethodHeaders, options?.headers);

    const response = await apiFetch(resource, {
      method,
      headers,
      ...options
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    return response.json();
  };
};

const get = fetchCurry('GET', { Accept: 'application/json' });
const post = fetchCurry('POST');
const patch = fetchCurry('PATCH');

interface TimeSlot {
  day: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
  week: 'EVEN' | 'ODD';
  start: string;
  finish: string;
}

interface CollegeInstitution {
  id: string;
  name: string;
}

interface Student {
  crsid: string;
  name: string;
  collegeInstitution: CollegeInstitution | null;
}

interface BatchBaseResponse {
  id: number;
  name: string;
  description: string;
  createdAt: string;
}

interface BatchResponse extends BatchBaseResponse {
  studentChoicesCollectionName: string;
  timetableName: string;
}

interface BatchDetailResponse extends BatchBaseResponse {
  timetable: Timetable;
  studentChoicesCollection: StudentChoicesCollection;
  studentChoices: JobStudentChoiceResponse[];
  jobs: Array<{
    id: number;
    studentCount: number;
    jobResultId: number | null;
    allocatedStudentCount: number | null;
    permittedClashCount: number | null;
    studentWithIncompatibleChoicesCount: number | null;
    overAllocatedPracticalCount: number | null;
    overAllocationSummed: number | null;
    chemistryPhysicsPerfectAlternationCount: number | null;
    chemistryPhysicsWeeklyAlternationCount: number | null;
  }>;
  metrics: {
    studentCount: number;
    studentTakingChemistryPhysicsCount: number;
  };
}

interface SubjectResponse {
  id: number;
  /** Unique code identifier, for example "NST1A_CH" */
  code: string;
  /** Name of the subject, for example "Chemistry" */
  name: string;
}

// FIXME: consolidate these types student choice types
interface JobStudentChoiceResponse {
  crsid: string;
  /** Array of subjects identifiers */
  subjects: string[];
}

interface StudentChoicesResponse {
  crsid: string;
  name: string;
  collegeInstitution: string;
  subjects: SubjectResponse[];
  createAt: string;
}

interface StudentChoicesCollection {
  id: number;
  name: string;
  createdAt: string;
}

interface Timetable {
  id: string;
  name: string;
}

interface TeachingUnit {
  code: string;
  type: 'PRACTICAL' | 'LECTURE';
  timeSlots: TimeSlot[];
}

interface StudentAllocationMetrics {
  allocated: boolean;
  allocationOptionCount: number;
  permittedClashCount: number;
  minimumPermittedClashCount: number;
}

interface StudentAllocation {
  id: number;
  student: string;
  teachingUnits: TeachingUnit[];
  metrics: StudentAllocationMetrics;
}

interface ResultMetrics {
  allocatedStudentCount: number;
  minimumPermittedClashCount: number;
  overAllocatedPracticalCount: number;
  overAllocationSummed: number;
  permittedClashesCountGrouped: Record<string, number>;
  permittedClashCount: number;
  studentWithIncompatibleChoicesCount: number;
  chemistryPhysicsPerfectAlternationCount: number;
  chemistryPhysicsWeeklyAlternationCount: number;
}

interface Result {
  id: number;
  studentAllocations: StudentAllocation[];
  metrics: ResultMetrics;
}

interface JobDetailResponse {
  id: number;
  batchId: number;
  result: Result | null;
}

interface PracticalAllocation {
  code: string;
  capacity: number;
  timeSlots: TimeSlot[];
  studentCount: number;
}

interface SubjectAllocation {
  name: string;
  code: string;
  practicals: PracticalAllocation[];
}

interface SubjectsResponse {
  subjects: SubjectAllocation[];
  metrics: {
    unevenlyDistributionScore: number;
  };
}

export default apiFetch;
export { get, post, patch };
export type {
  BatchDetailResponse,
  BatchResponse,
  JobDetailResponse,
  PracticalAllocation,
  Student,
  StudentChoicesResponse,
  StudentChoicesCollection,
  SubjectsResponse,
  TeachingUnit,
  Timetable,
  TimeSlot
};
